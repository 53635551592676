import Vue from "vue";
import axios from "axios";

export default {
};


export const api = {
  install(Vue) {
    Vue.prototype.$api = {
      getDomain: function() {
        if (location.host.indexOf("hospital.flydoctor.kr") >= 0) {
          return "https://api.flydoctor.kr";
        } else if (location.host.indexOf("localhost") >= 0 ) {
          return "http://localhost:8003/doctor365-9a68d/asia-northeast3/apiFlydoctor";
        } else {
          return "https://api-dev.flydoctor.kr";
        }
      },
      call: async function (path, reqBody) {
        const base_url = this.getDomain();
        const url = base_url + path;
        // console.log("* api base_url : " + base_url);

        let api_data = {
          "url": url,
          "method": 'POST',
          "headers": { 'content-type': 'application/json' },
          "data": reqBody
        }
        return await axios(api_data)
          .then(function (response) {
            // console.log("* api.call()")
            // console.log(response.data)
            return response.data;
          })
          .catch(function (error) {
            console.log(error);
            return {};
          })
      },
      getStockData: async function(reqBody) {
        let res = await this.call("/hospital/getStockData", reqBody);
        if (res.result === 200) {
          return res;
        } else {
          return {};
        }
      },
      getSavedDateList: async function(stock_date, hospital_id) {
        let reqBody = {
          stock_date: stock_date,
          hospital_id: hospital_id
        }
        let res = await this.call("/hospital/getSavedDateList", reqBody);
        if (res.result === 200) {
          return res.savedDayList;
        } else {
          return {};
        }
      }
    }
  }
};

Vue.use(api);